import { isFunction } from "lodash";
// import { remoteservice } from "./remote-service";
// import auth from "../services/auth";
import { Auth, FirebaseAuth } from "../services/new-auth";

import { setDebouncing } from "../actions/debounce";
import {
  FETCH_APP_SETTINGS,
  LOGIN,
  LOGIN_WITH_GOOGLE,
  LOGOUT,
  ONBOARDING_VIA_INVITE,
  REMOVE_AUTH_USER,
  SET_APP_SETTINGS,
  SET_CURRENT_PAGE_PROPS,
  SET_LATEST_ORGANIZATION_ID,
  SIGNUP,
  UPDATE_AUTH_USER,
} from "../constants/context";
import { RESET_ORGANIZATION_STATE } from "../constants/organization";
import { AppSettings, AuthUser, ContextState, CurrentPageProps } from "../reducers/context";
import { State } from "../reducers/index";
import { fetchInternalApi } from "../services/internal-api";
type Callback0 = (err?: Error) => void;
type Callback2 = (err: Error, data?: any) => void;
type DispatchFn<T> = (dispatch: () => any) => T;
type DispatchAndStoreFn<T> = (dispatch: () => any, getState: () => State.All) => T;

export type Login = {
  type: LOGIN,
  callback?: () => void
};
export function login(email: string, password: string, callback: Callback2 = err => { }): DispatchAndStoreFn<Login> {
  return function (dispatch: any, getState: () => State.All) {
    const auth: Auth =  new FirebaseAuth();
    const promise = auth.login(email, password)
      .then(authResult => {
        const authUser: AuthUser = {
          id: authResult?.user?.uid,
          debounceValid: true,
          email: authResult?.user?.email,
          emailVerified: authResult?.user?.emailVerified,
        };
        return authUser;
      })
      .then((authUser: AuthUser) => {
        dispatch(updateAuthUser({ authUser }));
        return authUser;
      });

    if (isFunction(callback)) {
      promise
        .then(authUser => callback(undefined, authUser));
    }

    promise.catch(err => callback(err));

    return {
      type: LOGIN,
    };
  };
}

export type SingUp = {
  type: SIGNUP,
  callback?: () => void
};
export function singUp(email: string, password: string, callback: Callback2 = err => { }): DispatchAndStoreFn<SingUp> {
  return function (dispatch: any, getState: () => State.All) {
    const auth: Auth =  new FirebaseAuth();
    const promise = auth.singup(email, password)
      .then(authResult => {
        const authUser: AuthUser = {
          id: authResult?.user?.uid,
          debounceValid: true,
          email: authResult?.user?.email,
          emailVerified: authResult?.user?.emailVerified,
        };
        return authUser;
      })
      .then((authUser: AuthUser) => {
        dispatch(updateAuthUser({ authUser }));
      });

    if (isFunction(callback)) {
      promise
        .then(authUser => callback(undefined, authUser));
    }

    promise.catch(err => callback(err));

    return {
      type: SIGNUP,
    };
  };
}

export type LoginWithGoogle = {
  type: LOGIN_WITH_GOOGLE,
  callback?: () => void
};
export function loginWithGoogle(callback: Callback2 = err => { }): DispatchAndStoreFn<LoginWithGoogle> {
  return function (dispatch: any, getState: () => State.All) {
    console.info("context login with google");
    const auth: Auth =  new FirebaseAuth();
    const promise = auth.loginWithGoogle()
      .then(authResult => {
        const authUser: AuthUser = {
          id: authResult?.user?.uid,
          email: authResult?.user?.email,
          emailVerified: authResult?.user?.emailVerified,
        };
        return debounceEmail(authUser);
      })
      .then((authUser: AuthUser) => {
        dispatch(updateAuthUser({ authUser }));
        // Set the flag to indicate we should show the debounce error message
        if (authUser.debounceValid === false) {
            dispatch(setDebouncing(true));
        }
        return authUser;
      });

    if (isFunction(callback)) {
      promise
        .then(authUser => callback(undefined, authUser));
    }

    promise.catch((err) => {
        console.info("got error: " + err);
        callback(err);
    });

    return {
      type: LOGIN_WITH_GOOGLE,
    };
  };
}

export function loginWithIbm(instanceId: string, code: string, callback: Callback2 = err => { }): DispatchAndStoreFn<Login> {
  return function (dispatch: any, getState: () => State.All) {
    const promise = fetchInternalApi(`/ibm/auth`, "POST", { instanceId, code }, { "Content-Type": "application/json" }, false)
      .then(({ authToken }) => {
        const auth: Auth = new FirebaseAuth();
        return auth.signInWithCustomToken(authToken);
      }).then(authResult => {
        const authUser: AuthUser = {
          id: authResult?.user?.uid,
          email: authResult?.user?.email,
          emailVerified: authResult?.user?.emailVerified,
        };
        return authUser;
      }).then((authUser: AuthUser) => {
        dispatch(updateAuthUser({ authUser }));
      });

    if (isFunction(callback)) {
      promise
        .then(authUser => callback(undefined, authUser));
    }

    promise.catch(err => callback(err));

    return {
      type: LOGIN,
    };
  };
}

export async function debounceEmail (authUser: AuthUser): Promise<AuthUser> {
    // Check if this is a good email
    if (!authUser.email){
      authUser.debounceValid = false;
      return authUser;
    }

    const emailValidation = await fetchInternalApi(`/login/validateEmail`,
        "POST",
        { email: authUser.email },
        { "Content-Type": "application/json" },
        false);

    if (emailValidation.isDisposable || emailValidation.isFreeMail) {
        authUser.debounceValid = false;
    } else {
        authUser.debounceValid = true;
    }
    return authUser;
}

export type Logout = {
  type: LOGOUT,
  callback?: () => void
};
export function logout(callback: Callback2 = err => { }): DispatchAndStoreFn<Logout> {
  return function (dispatch: any, getState: () => State.All) {
    const auth: Auth =  new FirebaseAuth();
    const promise = auth.logout()
      .then(() => {
        localStorage.removeItem('activeSession');
        dispatch(removeAuthUser());
        dispatch({ type: RESET_ORGANIZATION_STATE});
      })
      .catch(error => {
        console.log("logout error", error);
        dispatch(removeAuthUser());
        dispatch({ type: RESET_ORGANIZATION_STATE});
      });

    if (isFunction(callback)) {
      promise
        .then(authUser => callback(undefined, authUser));
    }

    promise.catch(err => callback(err));

    return {
      type: LOGOUT,
    };
  };
}

export type UpdateAuthUser = {
  type: UPDATE_AUTH_USER,
  contextState: ContextState

};
export function updateAuthUser(contextState: ContextState): UpdateAuthUser {
  return {
    type: UPDATE_AUTH_USER,
    contextState,
  };
}

export type RemoveAuthUser = {
  type: REMOVE_AUTH_USER,

};
export function removeAuthUser(): RemoveAuthUser {
  return {
    type: REMOVE_AUTH_USER,
  };
}

export type SetLatestOrganizationId = {
  type: SET_LATEST_ORGANIZATION_ID,
  latestOrganizationId: string
};

export function setLatestOrganizationId(latestOrganizationId: string): SetLatestOrganizationId {
  return {
    type: SET_LATEST_ORGANIZATION_ID,
    latestOrganizationId
  };
}


export type SetOnboardingViaInvite = {
    type: ONBOARDING_VIA_INVITE,
    onboardingViaInvite: boolean
  };

export function setOnboardingViaInvite(onboardingViaInvite: boolean): SetOnboardingViaInvite {
return {
    type: ONBOARDING_VIA_INVITE,
    onboardingViaInvite
};
}

export type FetchAppSettings = {
  type: FETCH_APP_SETTINGS,
  callback?: () => void
};
export function fetchAppSettings(callback: Callback0 = err => { }): DispatchAndStoreFn<FetchAppSettings> {
  return function (dispatch: any, getState: () => State.All) {

    const promise = fetchInternalApi(`/apps/dashboard/settings`);
    promise.then((appSettings: AppSettings) => {
      dispatch(setAppSettings(appSettings));

    });

    if (isFunction(callback)) {
      promise
        .then(() => callback(undefined));
    }

    promise.catch(err => callback(err));

    return {
      type: FETCH_APP_SETTINGS,
    };
  };
}


export type SetAppSettings = {
  type: SET_APP_SETTINGS,
  appSettings: AppSettings
};

export function setAppSettings(appSettings: AppSettings): SetAppSettings {
  return {
    type: SET_APP_SETTINGS,
    appSettings
  };
}

export type SetCurrentPageProps = {
  type: SET_CURRENT_PAGE_PROPS,
  currentPageProps: CurrentPageProps
};

export function setCurrentPageProps(currentPageProps: CurrentPageProps): SetCurrentPageProps {
  return {
    type: SET_CURRENT_PAGE_PROPS,
    currentPageProps
  };
}

export type ContextReduxActions = Login | SingUp | SetOnboardingViaInvite | LoginWithGoogle | UpdateAuthUser | RemoveAuthUser | SetLatestOrganizationId | SetAppSettings | SetCurrentPageProps;
