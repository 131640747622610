  import * as cn from "classnames";
  import { chain, concat, defaultTo, get, isEmpty, reduce, toLower, toUpper, trim } from "lodash";
  import * as React from "react";
  import { Button, IconButton } from "react-toolbox/lib/button";
  import { Chip } from "react-toolbox/lib/chip";
  import BespokenDownloadIcon from "../../../assets/bespoken_download_icon_2.svg";
  import BespokenIconCollapsed from "../../../assets/bespoken_icon_collapsed.svg";
  import BespokenIconExpanded from "../../../assets/bespoken_icon_expanded.svg";
  import BespokenIconFailed from "../../../assets/bespoken_icon_failed.svg";
  import BespokenIconPassed from "../../../assets/bespoken_icon_passed.svg";
  import BespokenPlayIcon from "../../../assets/bespoken_icon_play_audio.svg";
  import BespokenIconSkipped from "../../../assets/bespoken_icon_skipped.svg";
  import { DebugIconButton } from "../lunacy/icon-buttons/IconButton";
  import { DebugPanel } from "../DebugPanel/DebugPanel"
  import { TestRunBreakdown } from "../../services/bespoken-reporting-api";
  import { AudioFile } from "../AudioPlayer/AudioFile";
  import { EMPTY_AUDIO } from "./EmptyAudio";
  import BespokenPlayAudio from "../../../assets/bespoken_run_all_icon.svg";
  import BespokenPauseAudio from "../../../assets/bespoken_pause_icon.svg";
  import { DownloadFile } from "../../components/AudioPlayer/DownloadFile";
  import Slider from "react-toolbox/lib/slider";
  import { VideoPlayerComponent } from "../Video/VideoPlayer";
  import { BespokenModal } from "../Modal/BespokenModal";

  const TrackSliderTheme = require("../AudioPlayer/baseTrackSliderTheme.scss");
  const DebugPanelStyles = require("../DebugPanel/DebugPanel.scss")
  const Styles = require("./TestDetailsTableStyle.scss");
  const DISABLED = false;
  const dataKeyName = "data";

  const TEST_STATUS_ICON: { [key: string]: any } = {
    PASSED: (<BespokenIconPassed />),
    FAILED: (<BespokenIconFailed />),
    SKIPPED: (<BespokenIconSkipped />),
  }
  const SUITE_STATUS_COLOR: { [key: string]: any } = {
    PASSED: Styles.suite_status_passed,
    FAILED: Styles.suite_status_failed,
    SKIPPED: Styles.suite_status_skipped
  }

  function AssertionDetailComponent({ data }: { data: any }) {
    if (!data.slot_content) return undefined;
    if (!data.slot_status) return undefined;
    if (!data.slot_content.expectedAssertions) return undefined;

    const { expectedAssertions, step_raw_response, failedAssertions }: { step_raw_response: any, failedAssertions: string[], expectedAssertions: any[], actualValue: string } = data.slot_content;
    const stepKey = `${data.slot_index.suite}:${data.slot_index.test}:${data.slot_index.step}`

    if (data.slot_status === 'FAILED') {
      return (<div key={`slotcontent-${stepKey}`} style={{ flex: '1 1 200px'}}>{
        failedAssertions
          .map((err, errIndex) => err.split('\n').filter((l: string) => !isEmpty(l))
            .map((v: string, lineIndex: number) => <span className={(lineIndex === 0 || 'Received:' === trim(v)) && Styles.assertion_title} key={`${errIndex}-${lineIndex}`} >{v}</span>).reduce((p: any, c: any, i: number) => i === 0 ? p.concat(c) : p.concat(<br key={`br-${i}`} />).concat(c), []))
          .reduce((p: any, c: any) => p.concat(<div key={`errors-${stepKey}`} className={Styles.assertion_slot}>{c}</div>), [])
      }</div>)
    }

    if (data.slot_status === 'PASSED') {
      // to generate increment ids
      const increment = { index: 0 }
      const nextKey = (prefix: string) => `${prefix}-${++increment.index}`

      return (<div key={`slotcontent-${stepKey}`} style={{ flex: '1 1 200px'}}>
        <div className={Styles.assertion_slot} key={`assertion-${stepKey}`} style={{ flex: '1 1 200px'}}>
          {
            expectedAssertions
              .map(({ _path, _operator, _localizedValue }) => {
                const operationText = _operator === '==' ? 'to be one of' : 'to not be one of';
                const actualValue = get(step_raw_response, _path)
                return { expectedTitle: `Expected value at [${_path}] ${operationText}:`, values: [].concat(_localizedValue), actualValue };
              })
              .reduce((prev, curr, errIndex) => {
                const { expectedTitle, values, actualValue } = curr;

                if (errIndex > 0) {
                  prev.push(<br key={nextKey("br")} />);
                  prev.push(<br key={nextKey("br")} />);
                }

                prev.push(<span className={Styles.assertion_title} key={nextKey(`assertion-title-${stepKey}`)}>{expectedTitle}</span>);
                prev.push(<br key={nextKey("br")} />);
                values.forEach((v, i) => {
                  prev.push(<span key={nextKey(`expected-${stepKey}-${errIndex}-${i}`)} >{v}</span>);
                  prev.push(<br key={nextKey("br")} />);
                })
                prev.push(<span className={Styles.assertion_title} key={nextKey(`title-received-${stepKey}`)}>Received:</span>);

                (actualValue.split("\n") as any[])
                  .map((v, i) => (<span key={nextKey(`actual-${stepKey}-${i}`)}>{v}</span>))
                  .reduce((prev, curr, i) => prev.concat(<br key={nextKey("br")} />, curr), [])
                  .forEach(e => prev.push(e))

                return prev;
              }, [])
          }
        </div>
      </div>)
    }

    return undefined
  }

  interface Ref<R> { current: R; }
  interface InlineIVRPlayerProps { conversationId: string; }
  interface InlineIVRPlayerState {
    url?: string;

    sliderValue: number;
    newStartAudioValue: number;

    isDraggingSlider: boolean;

    paused: boolean;
    loadingFile: boolean;
    secondsElapsed: number;

    disable: boolean;
    conversationId: string;
  }
  class InlineIVRPlayer extends React.Component<InlineIVRPlayerProps, InlineIVRPlayerState> {

    state: InlineIVRPlayerState;
    props: InlineIVRPlayerProps;

    audioFile: Ref<AudioFile> = { current: undefined }

    constructor(props: InlineIVRPlayerProps) {
      super(props);

      this.state = {
        conversationId: props.conversationId,
        url: undefined,
        sliderValue: 0,
        newStartAudioValue: 0,
        isDraggingSlider: false,
        paused: true,
        loadingFile: true,
        secondsElapsed: 0,
        disable: false
      };
      
    }

    componentDidMount(): void {
      const { conversationId } = this.state;
      if (!isEmpty(this.state.conversationId)) {
        const VIRTUAL_DEVICE_TWILIO_URL = process.env.VIRTUAL_DEVICE_TWILIO_URL

        fetch(`${VIRTUAL_DEVICE_TWILIO_URL}/twilio_recording?callSid=${conversationId}`)
          .then(r => r.json())
          .then(({ recordingUrl: url }) => this.setState({ url }))
          .catch(err => err.message)
      }
    }

    async componentWillReceiveProps(nextProps: Readonly<InlineIVRPlayerProps>, nextContext: any): Promise<void> {
      const { conversationId } = nextProps;

      if (conversationId !== this.state.conversationId) {

        const VIRTUAL_DEVICE_TWILIO_URL = process.env.VIRTUAL_DEVICE_TWILIO_URL

        const url = await fetch(`${VIRTUAL_DEVICE_TWILIO_URL}/twilio_recording?callSid=${conversationId}`)
          .then(r => r.json())
          .then(({ recordingUrl }) => recordingUrl)
          .catch(err => err.message)

        this.setState({ url })
      }
    }

    componentWillUnmount() {
      // Stop audio playback when component unmounts
      if (this.audioFile.current) {
          this.audioFile.current.pause();
      }
  }

    async playAudioFromUrl(utterance_audio_url: string): Promise<void> {
      fetch(utterance_audio_url)
        .then(res => res.json())
        .catch(err => { console.log(err); EMPTY_AUDIO.play() })
    }

    formatHMS(value: number | any) {
      const sec = parseInt(value, 10); // convert value to number if it's string
      let hours: any = Math.floor(sec / 3600); // get hours
      let minutes: any = Math.floor((sec - (hours * 3600)) / 60); // get minutes
      let seconds: any = sec - (hours * 3600) - (minutes * 60); //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) { hours = "0" + hours; }
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      if (hours === '00') {
        return minutes + ':' + seconds; // Return is HH : MM : SS
      } else {
        return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
      }
    }

    render(): false | JSX.Element {
      const { url,
        sliderValue,
        secondsElapsed,
        newStartAudioValue,
        paused,
        loadingFile,
        isDraggingSlider,
        disable
      } = this.state
      const downloadFile: Ref<DownloadFile> = { current: undefined }
      console.log(`conversation: ${this.props.conversationId} disable: ${disable}`)
      const disableActions = disable || loadingFile;
      return (
        <div className={cn(Styles.audio_player, loadingFile ? Styles.hide : undefined)}>
          <DownloadFile
            {...{ url }}
            ref={ref => downloadFile.current = ref}
            onError={err => console.error(err)}
            onSucccess={filename => console.log("File downloaded", filename)}
          />
          <AudioFile
            url={url}
            newStartAudioValue={newStartAudioValue}
            ref={ref => this.audioFile.current = ref}

            onFileLoading={() => this.setState({ loadingFile: true })}
            onFileLoaded={() => this.setState({ loadingFile: false })}
            onPlaying={() => this.setState({ paused: false })}
            onPaused={() => this.setState({ paused: true })}
            onEnd={() => this.setState({ paused: true })}
            onUpdateTime={(secondsElapsed, sliderValue, duration) => {
              if (isDraggingSlider) {
                this.setState({ secondsElapsed })
              } else {
                this.setState({ sliderValue, secondsElapsed })
              }
            }
            }
          />
          <div className={cn(Styles.controls, disableActions ? Styles.hide : undefined)}>
            <div className={Styles.play}>
              {
                paused ?
                  <BespokenPlayAudio
                    onClick={() => { if (!disableActions) { this.audioFile?.current?.play() } }}
                    className={cn(disableActions ? Styles.disable : undefined)}
                  />
                  :
                  <BespokenPauseAudio
                    onClick={() => { if (!disableActions) { this.audioFile?.current?.pause() } }}
                    className={cn(disableActions ? Styles.disable : undefined)}
                  />
              }
            </div>
            <div>
              <Slider
                className={Styles.slider}
                disabled={disableActions}
                theme={TrackSliderTheme}
                value={this.state.sliderValue}
                onChange={(sliderValue: number) => {
                  this.setState({ sliderValue: Math.max(sliderValue, 1) })
                }}
                onDragStart={() => this.setState({ isDraggingSlider: true })}
                onDragStop={() => this.setState({ sliderValue, newStartAudioValue: sliderValue, isDraggingSlider: false })}
              />
            </div>
            <div><span>{this.formatHMS(secondsElapsed)}</span></div>
            <div>
              <BespokenDownloadIcon
                onClick={async () => { if (!disableActions) { await downloadFile?.current?.download() } }}
                className={cn(disableActions ? Styles.disable : undefined)}
              />
            </div>
          </div>
        </div>
      )

    }
  }

  interface HeaderTestRunBreakdown extends TestRunBreakdown {
    expanded?: boolean;
  }

  export interface TestDetailsTableProps {
    [dataKeyName]: TestRunBreakdown[];
  }

  type Rows = { header: HeaderTestRunBreakdown, items: TestRunBreakdown[] }[];
  export interface TestDetailsTableState {
    data: Rows;
    debugContent: any;
    showDebug: boolean;
  }

  export class TestDetailsTable extends React.Component<TestDetailsTableProps, TestDetailsTableState> {

    state: TestDetailsTableState;
    props: TestDetailsTableProps;

    constructor(props: TestDetailsTableProps) {
      super(props);

      this.state = {
        data: [],
        debugContent: undefined,
        showDebug: false,
      };
    }

    componentWillReceiveProps(nextProps: Readonly<TestDetailsTableProps>, nextContext: any): void {
      /*
      The result must have following format:
      [
      [header:{level_1},items:[{level_2},{level_3},{level_3},{level_2},{level_3},{level_3}]],
      [header:{level_1},items:[{level_2},{level_3},{level_3},{level_2},{level_3},{level_3}]]
      ]
      */
      const response = (get(nextProps, dataKeyName, []) as TestRunBreakdown[])

      const toLocaleFormatCase = (locale: string) => defaultTo(locale, '').split('-').reduce((p, c, i) => i === 0 ? p.concat(toLower(c)) : p.concat(toUpper(c)), []).join('-')

      const suites = response
        .reduce((p, c) => p.some(({ test_suite_id }) => test_suite_id === c.test_suite_id) ? p : p.concat(c), [])
        .map((suiteRow: TestRunBreakdown) => {
          const slot_type = 'level_1';
          const slot_index = { suite: suiteRow.test_suite_id };
          const slot_title = `${toUpper(suiteRow.test_suite_name)} ${suiteRow.locale ? `(${toLocaleFormatCase(suiteRow.locale)})` : ''}`;
          const slot_status = suiteRow.test_suite_result;

          return { slot_type, slot_index, slot_title, slot_status, slot_content: undefined }
        })

      const tests = response
        .reduce((p, c) => p.some(({ test_result_id }) => test_result_id === c.test_result_id) ? p : p.concat(c), [])
        .map(testRow => {
          const slot_type = 'level_2';
          const slot_index = { suite: testRow.test_suite_id, test: testRow.test_result_id }
          const slot_title = testRow.test_name;
          const slot_status = testRow.test_result;
          const conversation_id = testRow.test_conversation_id;
          const isIVR = testRow.test_platform === 'phone';
          const isChat = testRow.test_platform === 'webchat';
          const videoURL = `https://store.bespoken.io/store/video/${conversation_id}.mp4`
          return { slot_type, slot_index, slot_title, slot_status, slot_content: { conversation_id, isIVR, isChat, videoURL } }
        });

      const steps = response
        .map((stepRow, interactionOrderIndex) => {
          const slot_type = 'level_3';
          const slot_index = { suite: stepRow.test_suite_id, test: stepRow.test_result_id, step: stepRow.interaction_order };
          const slot_title = stepRow.message;
          const slot_status = stepRow.result;

          const slot_content: { step_raw_response: any, utterance_audio_url?: string, expectedAssertions: string[], failedAssertions: string[], actualValue: string } = { step_raw_response: {}, failedAssertions: [], expectedAssertions: [], actualValue: '' }

          slot_content.expectedAssertions = JSON.parse(stepRow.assertion)
          slot_content.utterance_audio_url = stepRow.utterance_audio_url;
          slot_content.step_raw_response = stepRow.step_raw_reponse;
          if (stepRow.result !== 'SKIPPED' && slot_content.step_raw_response) {
            if (!slot_content.step_raw_response) slot_content.step_raw_response = {}
            slot_content.step_raw_response.conversationID = stepRow.test_conversation_id || '';
          }
          if (stepRow.result === 'FAILED') {
            const errors: string[] = JSON.parse(stepRow.errors) || [];
            slot_content.failedAssertions = errors
          }

          return { slot_type, slot_index, slot_title, slot_status, slot_content, interactionOrderIndex }
        });

      const data = suites.reduce((p, c: any) => {
        const items = tests
          .filter(it => it.slot_index.suite === c.slot_index.suite)
          .reduce((p1, test) => {
            p1.push(test)
            steps.filter(step => step.slot_index.suite === c.slot_index.suite && step.slot_index.test === test.slot_index.test)
              .forEach(s => p1.push(s))
            return p1;
          }, [])

        p.push({ header: { ...c, expanded: true }, items });
        return p;
      }, []);

      this.setState({ data })
    }

    render() {
      const childrenExpanded = this.state.data
        .map(it => it.header && !!it.header.expanded)
        .reduce((p, c, i) => i === 0 ? c : p && c, false);

      return (
        <div className={Styles.layout}>
          <BespokenModal className={cn(DebugPanelStyles.dialog)}
              showModal={this.state.showDebug}
              title={"Debug Output"}
              redTitleBorder
              dialogToggle={() => this.toggleDialog()}>

              <DebugPanel content={this.state.debugContent } />
          </BespokenModal>
          <div className={Styles.top_bar}>
            <Button
              className={Styles.component_button}
              onClick={() => this.toggleAll()}
            >{childrenExpanded ? "Collapse all" : "Expand all"}</Button>
          </div>
          {
            this.state.data
              .map(({ header, items }) => {
                return (
                  <div key={`${header.slot_index.suite}:${header.slot_index.test}:${header.slot_index.step}`} className={cn(Styles.test_suites, Styles.level_0)}>
                    {
                      chain([
                        (<div className={Styles.level_0_expand_button} >
                          {header.expanded ?
                            <a onClick={() => { this.updateExpandedFlag(false, header); }}><BespokenIconExpanded /></a>
                            : <a onClick={() => { this.updateExpandedFlag(true, header); }}><BespokenIconCollapsed /></a>
                          }
                        </div>)

                        , (<div className={Styles.level_0_status}>
                          <Chip
                            key={`${header.slot_index.suite}:${header.slot_index.test}:${header.slot_index.step}`}
                            className={SUITE_STATUS_COLOR[header.slot_status]}
                          >
                            <span>{header.slot_status}</span>
                          </Chip>
                        </div>)

                        , (<div className={Styles.level_0_title}>
                          <span className={Styles.title}>{header.slot_title}</span>
                        </div>)
                      ])
                        .concat(
                          chain(items)
                            .thru(all => header.expanded ? all : [])
                            .map(stepData => {
                              if (stepData.slot_type === 'level_2') {
                                return [
                                  (<div className={Styles.level_2_expand_button}>
                                    {stepData.expanded
                                      ? <a
                                        className={Styles.component_button}
                                        onClick={() => { this.updateTestExpandedFlag(false, stepData) }}>
                                        <BespokenIconExpanded />
                                      </a>
                                      : <a
                                        className={Styles.component_button}
                                        onClick={() => { this.updateTestExpandedFlag(true, stepData) }}>
                                        <BespokenIconCollapsed />
                                      </a>
                                    }
                                  </div>
                                  )

                                  , (<div className={Styles.level_2_title}>
                                    <span key={`title-${stepData.slot_index.suite}:${stepData.slot_index.test}:${stepData.slot_index.step}`} style={{ marginBottom: 'auto' }} className={cn(Styles.title, stepData.slot_status === 'FAILED' ? Styles.failed : undefined)}>
                                      {stepData.slot_title}
                                    </span>
                                  </div>
                                  )

                                  , ((stepData.slot_content as any).isIVR && (stepData.slot_status as any) !== 'SKIPPED' &&
                                    <div
                                      className={Styles.level_2_inline_player}>
                                      <InlineIVRPlayer conversationId={(stepData.slot_content as any).conversation_id}
                                        key={`ivr-player-${stepData.slot_index.suite}:${stepData.slot_index.test}:${stepData.slot_index.step}`
                                        } />
                                    </div>
                                  )
                                  , ((stepData.slot_content as any).isChat && (stepData.slot_status as any) !== 'SKIPPED' &&
                                  <div
                                    className={Styles.level_2_inline_player}>
                                    <div>
                                      {/* <div className={styles.text_blue_label_small} style={{ textAlign: 'left' }}>Video Recording:</div> */}
                                      <VideoPlayerComponent  autoPlay={false} height={100} loaded={true} url={(stepData.slot_content as any).videoURL} />
                                    </div>
                                  </div>
                                )
                                ]
                              }

                              return (stepData.expanded && [
                                , (<div className={cn(Styles.level_3_status_icon)}>{TEST_STATUS_ICON[stepData.slot_status]}</div>)

                                , (<div className={Styles.level_3_title}>
                                  <span key={`title-${stepData.slot_index.suite}:${stepData.slot_index.test}:${stepData.slot_index.step}`} className={cn(Styles.title, stepData.slot_status === 'FAILED' ? Styles.failed : undefined)}>
                                    {stepData.slot_title}
                                    {/* Level3 audio only when has error: IN_PROGRESS */((DISABLED && !isEmpty(stepData.slot_content) && stepData.slot_status !== 'SKIPPED') && <BespokenPlayIcon key={`play-${stepData.slot_index.suite}:${stepData.slot_index.test}:${stepData.slot_index.step}`} onClick={() => this.playAudioFromUrl((stepData.slot_content[0] as any).utterance_audio_url)} />)}
                                  </span>
                                </div>)

                                , ((/(PASSED|FAILED)/ig.test(stepData.slot_status)) &&
                                      <div className={Styles.level_3_details}>
                                          <div style={{ display: 'flex', flexDirection: 'row'}}>
                                              <AssertionDetailComponent data={stepData} />
                                              <div style={{ flex: '0 0 20px'}}>
                                                  {(stepData.slot_content as any).step_raw_response !== null &&
                                                      <DebugIconButton
                                                          color={"color_spanish_gray"}
                                                          title={"View Debug Output"}
                                                          onClick={() => this.toggleDebugPanel((stepData.slot_content as any).step_raw_response)}
                                                      />
                                                  }
                                              </div>
                                          </div>
                                      </div>)
                              ])
                              /*

                                  className={Styles[stepData.slot_type]}
                                  key={`${stepData.slot_index.suite}:${stepData.slot_index.test}:${stepData.slot_index.step}`}
                              */
                            }
                            )
                            .value() as any[]
                        )
                        .value()
                    }
                  </div>
                )
              })
          }
        </div >
      );
    }

    async playAudioFromUrl(utterance_audio_url: string): Promise<void> {
      fetch(utterance_audio_url)
        .then(res => res.json())
        .then(res => console.log(res.body))
        .catch(err => EMPTY_AUDIO.play())
    }

    toggleAll() {
      const childrenExpanded = this.state.data.map(({ header }) => header.expanded).every(v => v === true)

      this.setState({
        data: this.state.data.map(it => {
          it.header.expanded = !childrenExpanded;
          chain(it.items)
            .filter(it => it.slot_type === 'level_2' || it.slot_type === 'level_3')
            .forEach(foundChild => {
              if (foundChild.slot_type === 'level_2') {
                foundChild.expanded = false
              }
              if (foundChild.slot_type === 'level_3') {
                foundChild.expanded = false
              }

            })
            .value()
          return it;
        })
      })
    }

    toggleDialog () {
      if (this.state.showDebug) {
          this.setState(prevState => ({ ...prevState, showDebug: false }))
      }
    }

    toggleDebugPanel (content: any) {
      if (this.state.showDebug) {
          this.setState(prevState => ({ ...prevState, showDebug: false }))
      } else {
          this.setState(prevState => ({ ...prevState, debugContent: content, showDebug: true }))
      }

    }

    updateExpandedFlag(val: boolean, header: HeaderTestRunBreakdown): void {
      header.expanded = val;
      this.setState({ data: this.state.data })
    }

    updateTestExpandedFlag(val: boolean, test: TestRunBreakdown): void {
      const data = chain(this.state.data)
        .forEach(suite => {
          chain(suite.items)
            .filter(it => it.slot_index.suite === test.slot_index.suite && it.slot_index.test === test.slot_index.test)
            .forEach(foundChild => { foundChild.expanded = val })
            .value()
        })
        .value()

      this.setState({ data: data as any })
    }
  }
