import * as cn from "classnames";
import { chain, filter, first, forEach, includes, isArray, isEmpty, isString, max, size, some, split, toLower, trim } from "lodash";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { IconMenu } from "react-toolbox/lib/menu";
import BespokenMenuDots from "../../../assets/bespoken_menu_dots.svg";
import { setLoading } from "../../actions/loading";
import { fetchOrganization, switchOrganization } from "../../actions/organization";
// import { logout } from "../../actions/session";
import { BespokenUserProperties } from "../../models/bespoken-user";
import { State } from "../../reducers";
import { Organization } from "../../reducers/organization";
import { fetchBespokenApi } from "../../services/bespoken-api";
import * as Intercom from "../../services/intercom";
import { attemptInvoke, cnToggle, wrapCallbackAsAsync } from "../../utils/ReactHelpers";
import { NormalModal } from "../Modal/WarningModal";
import { Line, PlusSignOnlyIconButton, TextBlueStrongLabelSmall, TextButtonSmall, TextErrorSmall, TextNormalDark } from "../lunacy";
import { ERROR, INFO } from "../lunacy/debug-panel/DebugPanel";
import { InputWithLabel } from "../lunacy/inputs/InputWithLabel";
import { HasPrivilege } from "../lunacy/privileges/HasPrivileges";
import { CircleAvatar } from "../lunacy/profile-avatar/CircleAvatar";
import { TextAreaWithLabel } from "../lunacy/inputs/TextareaWithLabel";
import { IconButton } from "react-toolbox/lib/button";
import ReactDiv from "../ReactDiv";
import Tooltip from "react-toolbox/lib/tooltip";
import { User } from "../../reducers/user";
import { AuthUser } from "../../reducers/context";
import { logout } from "../../actions/context";

const TooltipDiv = Tooltip(ReactDiv);
const globalWindow: any = typeof (window) !== "undefined" ? window : {};
const Styles = require("./styles.scss");
const IconMenuTheme = require("./iconMenuTheme.scss");

interface DispatchToProps {
    goTo: (path: string) => void;
    login: () => void;
    logout: (email: string) => Promise<void>;
    switchOrganization: (organizationId: string) => Promise<any>;
    setLoading: (value: boolean) => any;
    fetchOrganization?: (organizationId?: string) => any;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        goTo: (path: string) => {
            dispatch(push(path));
            globalWindow && Intercom.updateIntercom(globalWindow);
        },
        login: () => {
            return dispatch(push("/login"));
        },
        logout: (email: string) => {
            globalWindow && Intercom.shutdownIntercom(globalWindow, email);
            return dispatch(logout());
        },
        switchOrganization: async (organizationId: string) => wrapCallbackAsAsync(handle => dispatch(switchOrganization(organizationId, handle))),
        setLoading: (value: boolean) => dispatch(setLoading(value)),
        fetchOrganization: (organizationId: string = "current") => wrapCallbackAsAsync(handle => dispatch(fetchOrganization(organizationId, handle))),
    };
}

interface StateToProps {
    userIsLogged?: boolean;
    ibmUser?: boolean;
    name: string;
    currentOrganization: Organization;
    otherOrganizations: Organization[];
    user: User;
    authUser: AuthUser;
}

function mapStateToProps(state: State.All): StateToProps {
    return {
        userIsLogged: !isEmpty(state?.context?.authUser),
        ibmUser: state?.organization?.selectedOrganization?.ibmUser,
        name: state?.user?.currentUser?.name,
        user: state?.user?.currentUser,
        authUser: state?.context?.authUser,
        currentOrganization: state?.organization?.selectedOrganization,
        otherOrganizations: chain(state?.organization?.organizations)
            .filter(o => o.id !== state?.organization?.selectedOrganization?.id)
            .sortBy(o => toLower(o?.name))
            .value()
    };
}

interface ExposedProps { }
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface ComponentState {
    name: string;
    canManageMembers: boolean;
    canSwitchOrganization: boolean;
    canCreateOrganization: boolean;
    showCreateOrganizationModal: boolean;
}

interface ComponentProps extends DispatchToProps, StateToProps, ExposedProps { }

class Component extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = {
            name: props.name,
            canManageMembers: false,
            canSwitchOrganization: false,
            canCreateOrganization: false,
            showCreateOrganizationModal: false
        };
    }

    render() {
        const { name, canManageMembers, canCreateOrganization } = this.state
        const { goTo, userIsLogged, login, logout, user } = this.props

        return (
            <IconMenu icon={<BespokenMenuDots />}
                position="topRight"
                menuRipple={true}
                theme={IconMenuTheme}
                ref={(it) => {
                    if (isEmpty(it)) { return }
                    // Calculates and sets top margin to appear below the button
                    const iconMenuDom = ReactDOM.findDOMNode(it)
                    const buttonDom = iconMenuDom.querySelector('button')
                    const gap = 12
                    const topMargin = buttonDom.clientHeight
                    const menuDiv = buttonDom.nextSibling as HTMLElement
                    menuDiv.style.marginTop = `${topMargin + gap}px`
                }}
            >
                <div
                    className={Styles.container}
                >
                    <HasPrivilege
                        onPrivilegesLoaded={privileges => this.setState({
                            canManageMembers: includes(privileges, "can-manage-members"),
                            canCreateOrganization: includes(privileges, "can-create-organization"),
                        })}
                    />

                    <div className={cn(Styles.organization)} title={this.props?.currentOrganization?.name}>
                        <TextBlueStrongLabelSmall>{this.props?.currentOrganization?.name}</TextBlueStrongLabelSmall>
                        <CircleAvatar
                            tooltip={this.props?.currentOrganization?.name}
                            label={this.buildInitials(this.props?.currentOrganization?.name)}
                            color={this.props?.currentOrganization?.profile?.color} imageUrl={undefined}
                        />
                    </div>
                    <div
                        className={cn(Styles.vertical_separator)}>
                        <Line orientation="vertical" />
                    </div>
                    <div className={cn(Styles.user)}>
                        <TextBlueStrongLabelSmall>{name}</TextBlueStrongLabelSmall>
                        <CircleAvatar
                            tooltip={`${this.props?.user?.name} (${this.props?.authUser?.email})`}
                            label={this.buildInitials(name, 2)}
                            color={this.props?.user?.profile?.color}
                            imageUrl={undefined} />
                    </div>


                    <div className={cn(Styles.manage_link)}>
                        {canManageMembers
                            && (<div key={'manage-organization-action'} >
                                <TextButtonSmall onClick={() => {
                                    attemptInvoke(goTo, '/organization')
                                    this.setState({})
                                }}>Manage</TextButtonSmall>
                            </div>)
                        }
                    </div>
                    <div key={'go-account-action'} className={Styles.my_account} >
                        <TextButtonSmall
                            onClick={() => {
                                attemptInvoke(goTo, '/account');
                                this.setState({});
                            }}
                        >My Account</TextButtonSmall>
                    </div>

                    <div className={cn(Styles.horizontal_separator)}>
                        <Line orientation="horizontal" />
                    </div>
                    <div className={Styles.read_docs}>
                        <TextButtonSmall
                            onClick={() => {
                                window.open("https://read.bespoken.io", "_blank");
                                this.setState({});
                            }}
                        >Read the docs</TextButtonSmall>
                    </div>

                    <div className={Styles.organizations_container}>
                    {
                        chain(this.props?.otherOrganizations)
                            .map((it) => ({
                                // data
                                ...it,
                                // react key for use on render
                                key: `key${it.id}`,
                                // on click handler function
                                switchFunction: async (organizationId: string) => {
                                    try {
                                        this.props?.setLoading(true)

                                        const payload = await this.props?.switchOrganization(organizationId)
                                        this.props.goTo('/skills')

                                        INFO("Organization switched to: ", JSON.stringify(payload))
                                    } catch (err) {
                                        ERROR("error while trying to switch organization", err)
                                    } finally {
                                        this.props?.setLoading(false)
                                    }
                                }
                            }))
                            .map((it, index) => (
                                <div
                                  key={it.key}
                                  className={cn(Styles.organization_switch_link)}
                                  title={it.name}
                                >
                                  <div onClick={() => it.switchFunction(it.id)}>
                                    <CircleAvatar
                                      label={this.buildInitials(it?.name, 2)}
                                      color={it?.profile?.color}
                                      imageUrl={undefined}
                                    />
                                  </div>
                                  <TextButtonSmall onClick={() => it.switchFunction(it.id)}>
                                    {it.name}
                                  </TextButtonSmall>
                                </div>
                              ))
                              .value()}
                          </div>

                    {<TooltipDiv
                        key={'btn-new-organization'}
                        className={cn(Styles.new_organization, cnToggle(!canCreateOrganization, Styles.disable_create_organization))}
                        tooltipPosition="right"
                        theme={require("../../themes/tooltip.scss")}
                        tooltip="(Coming soon) To create multiple organizations contact us at support@bespoken.io"
                    >
                        <PlusSignOnlyIconButton
                            color="color_lavender_gray"
                            hoverColor="color_lavender_gray"
                            onClick={() => {
                                if (!canCreateOrganization) { return }
                                this.setState({ showCreateOrganizationModal: true })
                            }
                            } />
                        <TextButtonSmall onClick={() => {
                            if (!canCreateOrganization) { return }
                            this.setState({ showCreateOrganizationModal: true })
                        }} >New organization</TextButtonSmall>
                        <IconButton
                            disabled={true}
                            icon={"info"}
                        />

                    </TooltipDiv>}
                    <div className={Styles.privacy_policy}>
                        <TextButtonSmall onClick={() => {
                            window.open("https://bespoken.io/privacy-policy", "_blank");
                            this.setState({});
                        }}>Privacy Policy</TextButtonSmall>
                    </div>
                    <div className={Styles.log_out}>
                        <TextButtonSmall
                            onClick={() => {
                                userIsLogged ? attemptInvoke(logout, user?.email) : attemptInvoke(login);
                                this.setState({});
                            }}
                        >Log out</TextButtonSmall>
                    </div>
                </div>


                <CreateOrganizationModal
                    onCreateOrganization={async data => {
                        this.props.setLoading(true)

                        try {
                            this.setState({ showCreateOrganizationModal: false })
                            const emails = data?.emails
                            const name = data?.name
                            const newOrganization = await fetchBespokenApi(({ userId }) => `/users/${userId}/organizations/`, 'POST', { name, emails, templateOrganizationId: this.props?.currentOrganization?.id })

                            if (isEmpty(newOrganization.id)) { throw new Error('Not valid organizationId') }

                            await this.props.fetchOrganization(newOrganization.id)
                            await this.props.goTo('/skills')
                        } catch (err) {
                            ERROR('Error creating organization', data)
                        }

                        this.props.setLoading(false)
                    }}
                    onDismissDialog={() => this.setState({ showCreateOrganizationModal: false })}
                    showModal={this.state?.showCreateOrganizationModal}
                />
            </IconMenu >
        );
    }

    buildInitials(value: string, numberOfLeters: number = 2) {
        return chain(value).snakeCase().toUpper().words().map(v => first(trim(v))).filter(v => !isEmpty(v)).take(numberOfLeters).map(v => isEmpty(v) ? '-' : v).join('').value()
    }
}

export const UserProfileMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Component);


type CreateOrganizationItem = { name: string, emails: string }
type CreateOrganizationProps = { showModal: boolean, onCreateOrganization: (item: CreateOrganizationItem) => void, onDismissDialog: () => void }
type CreateOrganizationState = {
    name: string;
    emails: string;
    emailsAreValid: boolean;
}
const CreateOrganizationModal = connect(
    undefined,
    undefined,
    function mergeProps(ownProps: any, stateProps: any, dispatchProps: CreateOrganizationProps) {
        return { ...ownProps, ...stateProps, ...dispatchProps }
    }
)(class extends React.Component<CreateOrganizationProps, CreateOrganizationState>{

    constructor(props: CreateOrganizationProps) {
        super(props);

        this.state = {
            name: undefined,
            emails: undefined,
            emailsAreValid: true,
        };
    }


    render(): false | JSX.Element {
        const { onDismissDialog, onCreateOrganization, showModal } = this.props
        const item = {}
        return (
            <NormalModal
                item={item}
                onFinally={() => this.setState({ name: '', emails: '' })}
                onDismissDialog={() => { attemptInvoke(onDismissDialog) }}
                onOkDialog={it => attemptInvoke<CreateOrganizationItem>(onCreateOrganization, { name: this.state?.name, emails: this?.state.emails })}
                showModal={showModal}
                title="New Organization"
                disableOkButton={!(this.state.emailsAreValid && !isEmpty(trim(this.state.name)))}
            >
                <div className={Styles.create_organization_modal}>
                    <div>
                        <TextNormalDark>
                            You'll be able to switch between organizations and shared resources from your user menu at the top right corner of the screen.
                        </TextNormalDark>
                    </div>
                    <div>
                        <InputWithLabel
                            maxLenght={50}
                            defaultValue=""
                            label="Organization Name"
                            value={this.state?.name}
                            onChange={name => this.setState({ name })}
                            autoFocus={true}
                        />
                    </div>
                    <div>
                        <TextNormalDark>
                            Invite other admins to your organization by typing their emails below. More users can be invited in the "Manage" screen after  you create your organization.
                        </TextNormalDark>
                    </div>
                    <div className={Styles.row_emails}>
                        <TextAreaWithLabel
                            multiline={false}
                            rows={2}
                            label="Emails"
                            value={this.state?.emails}
                            hasError={!this.state?.emailsAreValid}
                            onChange={emails => this.setState({ emails, emailsAreValid: isEmpty(emails) })}
                        />
                        <ValidateEmails
                            emailsToValidate={this.state?.emails}
                            onChange={(emailsAreValid) => {
                                this.setState({ emailsAreValid })
                                console.log({ emailsAreValid })
                            }}
                        />
                        <TextErrorSmall>{!this.state.emailsAreValid && "Some emails contain errors, please revise them and try again."}</TextErrorSmall>
                        <TextNormalDark>Enter one or more valid email addresses separated by whitespace, ',', or ';'</TextNormalDark>
                    </div>
                </div>
            </NormalModal>
        )
    }

})



type ValidateEmailsProps = { emailsToValidate: string; onChange: (val: boolean) => void; }
type ValidateEmailsState = { emails: string, valid: boolean, empty: boolean }
const ValidateEmails = class extends React.Component<ValidateEmailsProps, ValidateEmailsState> {

    timeoutId: any = undefined;

    constructor(props: ValidateEmailsProps) {
        super(props)

        this.state = {
            emails: props.emailsToValidate,
            valid: undefined,
            empty: true
        }
    }

    componentWillReceiveProps(nextProps: Readonly<ValidateEmailsProps>, nextContext: any): void {
        clearTimeout(this.timeoutId)

        if (nextProps.emailsToValidate !== this.state.emails) {
            const { emailsToValidate: emails } = nextProps
            this.setState({ emails }, () => {
                this.timeoutId = setTimeout(() => this.validateEmail(), 1000)
            })
        }
    }

    async validateEmail() {
        const { emails } = this.state
        const { onChange } = this.props
        const completeCallback = () => attemptInvoke(onChange, this.state.valid)

        const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const emailsParsedAreValid = chain(emails)
            .thru(all => isString(all) ? split(all, /[,; \n]/) : all)
            .map(s => trim(s))
            .filter(s => !isEmpty(s))
            .thru(all => {
                if (isEmpty(all)) { return true }

                return !some(all, email => !EMAIL_REGEXP.test(toLower(email)))
            })
            .value()


        if (!emailsParsedAreValid) {
            return this.setState({ valid: false }, completeCallback)
        }

        return this.setState({ valid: true }, completeCallback)
    }

    render(): false | JSX.Element {
        return false
    }
}

